<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/user/fictitious' }">虚拟用户</el-breadcrumb-item>
                <el-breadcrumb-item>添加</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <el-row>
                <el-form size="medium" ref="form" :label-width="this.env.label_width">
                    <el-col :span="24">
                        <el-form-item label="昵称">
                            <el-input v-model="form.user_nick"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="手机号">
                            <el-input type="number" v-model="form.phone"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="头像">
                            <Qnupload v-model="form.avatar_url" :sum="1" :isclipper="true" :fixedNumber="[1,1]"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="性别">
                            <el-radio v-model="form.gender" label="1">男</el-radio>
                            <el-radio v-model="form.gender" label="2">女</el-radio>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="关注时间">
                            <el-date-picker
                                    v-model="form.create_time"
                                    type="date"
                                    :picker-options="pickerOptions"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item>
                            <el-button v-if="is_auth('user.fictitious.isadd')"
                                       size="medium" type="primary" @click="save">保存
                            </el-button>
                            <el-button  size="medium" @click="isreturn">返回</el-button>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-row>
        </div>
    </div>
</template>
<script>
import Qnupload from '@/components/Qnupload'

export default {
    components: {
        Qnupload,
    },
    data() {
        return {
            form: {
                gender: '1',
                avatar_url: '',
                create_time: '',
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()

                }
            },
        }
    },
    // 创建
    created() {

    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        save() {
            let postdata = {
                api_name: "user.fictitious.isadd",
                token: this.Tool.get_l_cache('token'),
            }
            Object.assign(postdata, this.form);
            console.log('postdata', postdata)
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.page = 1
                            this.form = {}
                            this.$router.push({path: '/user/fictitious'})
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            // this.$router.push({path: '/store/store'})
            this.$router.go(-1)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
